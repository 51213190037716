<template>
  <layout title="Produtos" :options="options" :rotas="rotas">
    <v-sheet
      style="min-height: 97vh; max-height: 97vh; overflow: hidden;"
      :dark="$theme.dark"
      :light="$theme.light"
      class="expande-horizontal pt-3 wrap"
    >
      <v-flex xs12 class="pr-1" lg3>
        <Filtros
          :filter="filter"
          :class="{ 'pl-0 ml-0': $vuetify.breakpoint.smAndUp }"
        />
        <v-flex v-if="get_insumos.docs.length > 0" xs12>
          <v-list
            class="pa-0 ma-0"
            dense
            :dark="$theme.dark"
            :light="$theme.light"
            background-color="transparent"
            style="min-height: 20vh; max-height: 90vh; overflow: auto;"
          >
            <template v-for="(insumo, index) in insumos">
              <v-list-item
                @click="abririnsumo(insumo, index)"
                style="border-radius: 6px;"
                :key="insumo._id"
                class="fonte pl-1 pr-1"
              >
                <v-list-item-content class="ml-2">
                  <v-list-item-title class="fonte fonteMini">
                    {{ insumo.nome }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="fonteMini">
                    {{ insumo.type }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="fonteMini">
                    {{ $helper.formataSaldo(insumo.ultimo_preco_de_compra) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-avatar rounded color="#e5e5e5" size="36">
                    <v-img
                      v-if="insumo.imagens[0]"
                      :src="insumo.imagens[0]"
                      :alt="insumo.nome"
                      :lazy-src="insumo.imagem"
                    ></v-img>
                    <v-icon v-else :color="$theme.primary">
                      mdi-cog-outline
                    </v-icon>
                  </v-avatar>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="index"></v-divider>
            </template>
            <v-list-item
              style="min-height: 200px;"
              v-intersect="onIntersect"
            ></v-list-item>
          </v-list>
        </v-flex>
        <v-flex v-if="get_insumos.docs.length === 0" xs12>
          <div
            class="expande-horizontal pa-3 wrap centraliza"
            style="height: 36vh;"
          >
            <img style="width: 100px;" src="img/empty.gif" alt="empty" />
            <span class="px-3 fonte-subtitulo grey--text text-center">
              Não encontramos nenhum produto, clique no botão abaixo e cadastre
              um!
            </span>
            <v-btn
              dark
              class="mt-6 fonte black--text"
              :color="$theme.primary"
              @click="iniciarCadastro"
            >
              Novo
              <v-icon
                size="12"
                color="#333"
                class="ml-1 animate__animated"
                :class="getPersonalizedLoading"
                >mdi-plus</v-icon
              >
            </v-btn>
          </div>
        </v-flex>
      </v-flex>
      <!-- <v-flex xs0 md0 lg1></v-flex> -->
      <v-flex v-if="$vuetify.breakpoint.lgAndUp" xs12 md9 lg9>
        <div
          v-if="get_insumo.new || get_insumo._id"
          class="expande-horizontal centraliza pt-1 wrap"
        >
          <!-- Header de cadastro de insumos -->
          <v-flex xs12>
            <div class="windows-style expande-horizontal fonte column">
              <transition name="slide-fade">
                <v-list-item class="pa-0 ma-0">
                  <v-avatar
                    @click="backToList"
                    style="cursor: pointer;"
                    size="27"
                    :color="$theme.primary"
                    class="mr-2"
                    icon
                  >
                    <v-icon color="#333">mdi-close</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      {{ get_insumo.new ? "Novo item" : get_insumo.nome }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-mini">
                      Gerencie os detalhes do item
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="get_insumo._id">
                    <v-btn
                      color="red"
                      @click="excluir_insumo_dialog(get_insumo)"
                      outlined
                      small
                      >Excluir</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
              </transition>
            </div>
          </v-flex>
          <div
            style="height: 83vh; overflow: auto;"
            class="windows-style-content pa-3 pt-0 expande-horizontal wrap"
          >
            <!-- Form de cadastro de insumos -->
            <v-flex xs12>
              <v-form ref="form">
                <v-flex xs12>
                  <div class="expande-horizontal wrap">
                    <div class="expande-horizontal">
                      <v-flex class="pt-3" xs12 md4>
                        <v-select
                          :items="[
                            'Especialidade',
                            // 'Placa',
                            // 'Acabamento',
                            // 'Peça Principal',
                            // 'Peça Secundária',
                            // 'Material'
                          ]"
                          v-model="get_insumo.type"
                          dense
                          outlined
                          label="Tipo de item"
                        ></v-select>
                      </v-flex>
                      <v-flex class="pr-3" xs12 md2>
                        <v-switch
                          label="Disponível"
                          class="fonte-subtitulo fonte-italica"
                          v-model="get_insumo.disponivel"
                        ></v-switch>
                      </v-flex>
                    </div>
                    <div class="expande-horizontal">
                      <ModalSendArchive @input="createOrUpdate" />
                    </div>
                    <v-flex class="pr-3" xs12 md3>
                      <v-text-field
                        label="Nome"
                        dense
                        outlined
                        class="fonte-subtitulo fonte-italica"
                        placeholder="ex: Clínico Geral"
                        v-model="get_insumo.nome"
                      ></v-text-field>
                    </v-flex>
                    <v-flex class="pr-3" xs12 md2>
                      <v-text-field
                        label="Preço"
                        dense
                        outlined
                        type="number"
                        v-mask="['#.##', '##.##', '###.##', '####.##']"
                        class="fonte-subtitulo fonte-italica"
                        placeholder="ex: 12"
                        v-model="get_insumo.ultimo_preco_de_compra"
                      ></v-text-field>
                    </v-flex>
                    <!-- <v-flex class="pr-3" xs12 md2>
                      <v-text-field
                        label="Estoque mínimo"
                        dense
                        outlined
                        class="fonte-subtitulo fonte-italica"
                        placeholder="ex: 12"
                        v-model="get_insumo.estoque_minimo"
                      ></v-text-field>
                    </v-flex> -->
                    <v-flex class="" xs12 md3>
                      <v-select
                        label="Unidade"
                        dense
                        outlined
                        class="fonte-subtitulo fonte-italica"
                        placeholder="ex: unidade"
                        type="Number"
                        :readonly="get_insumo._id ? true : false"
                        :items="unidades_de_medida_base"
                        item-text="nome"
                        return-object
                        @input="forceUpdate"
                        @change="forceUpdate"
                        :rules="[v => !!v || 'Campo obrigatório']"
                        v-model="get_insumo.unidade_estoque_minimo"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12>
                      <v-btn
                        class="fonte"
                        :color="$theme.third"
                        dark
                        outlined
                        @click="createOrUpdate"
                        >Salvar detalhes do item</v-btn
                      >
                    </v-flex>
                  </div>
                </v-flex>
              </v-form>
            </v-flex>
            <!-- Gerência do estoque -->
            <!-- <v-flex v-if="get_insumo._id" class="mt-6 mb-3" xs12> -->
            <v-flex v-if="false" class="mt-6 mb-3" xs12>
              <div class="expande-horizontal fonte column">
                <v-flex class="mb-3" xs12>
                  <transition name="slide-fade">
                    <v-list-item
                      @click="iniciaCadastroEstoque"
                      class="pa-0 ma-0"
                    >
                      <v-avatar color="#47CF72" class="mr-2" icon>
                        <v-icon dark>mdi-cube-send</v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">
                          {{ get_insumo.new ? "Novo item" : get_insumo.nome }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="font-mini">
                          {{ quantidadeEstoque.quantidade
                          }}{{
                            get_insumo && get_insumo.unidade_estoque_minimo
                              ? get_insumo.unidade_estoque_minimo.valor
                              : ""
                          }}
                          em estoque
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          :color="$theme.third"
                          class="text-capitalize"
                          @click="iniciaCadastroEstoque"
                          small
                        >
                          <v-icon color="white" class="mr-1" size="15"
                            >mdi-plus-circle-outline</v-icon
                          >
                          <span class="white--text fonte fonte-mini"
                            >Add Estoque</span
                          >
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </transition>
                </v-flex>
                <v-flex xs12>
                  <v-list color="transparent" dense class="pa-0 ma-0">
                    <template v-for="estoque in get_estoques.docs">
                      <v-list-item
                        style="border-radius: 5px; background: #f2f2f2;"
                        class="ma-0 mb-2 pa-0"
                        :key="estoque._id"
                      >
                        <v-avatar
                          class="mx-3"
                          color="green"
                          v-if="estoque.type === 'in' || !estoque.type"
                        >
                          <v-icon size="18" color="white">
                            mdi-arrow-up-circle-outline
                          </v-icon>
                        </v-avatar>
                        <v-avatar class="mx-3" color="red" v-else>
                          <v-icon size="18" class="ml-2 mr-2" color="white">
                            mdi-arrow-down-circle-outline
                          </v-icon>
                        </v-avatar>
                        <v-list-item-content
                          class="fonte-subtitulo fonte-italica font-weight-bold"
                        >
                          <v-list-item-title>
                            {{ estoque.quantidade }}
                            {{
                              estoque && estoque.unidade_de_medida
                                ? estoque.unidade_de_medida.valor
                                : ""
                            }}
                            de
                            {{ estoque.produto.nome }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="estoque.type === 'in'">
                            Preço Base:
                            {{ $helper.formataSaldo(estoque.preco_de_compra) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="estoque.type === 'in'">
                            Total comprado:
                            {{ sumValueBuyed(estoque) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="fonte-micro"
                            >{{
                              $moment(estoque.created_at).format(
                                "DD/MM/YY [às] HH:mm"
                              )
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-flex>
              </div>
            </v-flex>
          </div>
        </div>
      </v-flex>
      <v-dialog
        v-else
        transition="slide-x-transition"
        fullscreen
        :value="get_insumo._id || get_insumo.new ? true : false"
      >
        <v-card class="pl-3">
          <div
            v-if="get_insumo.new || get_insumo._id"
            class="expande-horizontal centraliza wrap"
          >
            <!-- Header de cadastro de insumos -->
            <v-flex class="mb-2" xs12>
              <div class="expande-horizontal fonte column">
                <transition name="slide-fade">
                  <v-list-item class="pa-0 ma-0">
                    <v-btn @click="backToList" class="mr-2" icon>
                      <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ get_insumo.new ? "Novo item" : get_insumo.nome }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-mini">
                        Gerencie os detalhes do item
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="get_insumo._id">
                      <v-btn
                        color="red"
                        @click="excluir_insumo_dialog(get_insumo)"
                        outlined
                        small
                        >Excluir</v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                </transition>
              </div>
            </v-flex>
            <!-- Form de cadastro de insumos -->
            <v-flex xs12 class="pa-2">
              <v-form ref="form">
                <v-flex xs12>
                  <div class="expande-horizontal wrap">
                    <div class="expande-horizontal">
                      <v-flex class="pt-3" xs12 md4>
                        <v-select
                          :items="['Especialidade']"
                          v-model="get_insumo.type"
                          dense
                          outlined
                          label="Tipo"
                        ></v-select>
                      </v-flex>
                      <v-flex class="pr-3" xs12 md2>
                        <v-switch
                          label="Disponível"
                          class="fonte-subtitulo fonte-italica"
                          v-model="get_insumo.disponivel"
                        ></v-switch>
                      </v-flex>
                    </div>
                    <div class="expande-horizontal">
                      <ModalSendArchive @input="createOrUpdate" />
                    </div>
                    <v-flex class="pr-3" xs12 md4>
                      <v-text-field
                        label="Nome"
                        dense
                        outlined
                        class="fonte-subtitulo fonte-italica"
                        placeholder="ex: Clínico Geral"
                        v-model="get_insumo.nome"
                      ></v-text-field>
                    </v-flex>
                    <!-- <v-flex class="pr-3" xs12 md2>
                      <v-text-field
                        label="Preço"
                        dense
                        outlined
                        type="number"
                        v-mask="['#.##', '##.##', '###.##', '####.##']"
                        class="fonte-subtitulo fonte-italica"
                        placeholder="ex: 12"
                        v-model="get_insumo.ultimo_preco_de_compra"
                      ></v-text-field>
                    </v-flex>
                    <v-flex class="pr-3" xs12 md4>
                      <v-text-field
                        label="Estoque mínimo"
                        dense
                        outlined
                        class="fonte-subtitulo fonte-italica"
                        placeholder="ex: 12"
                        v-model="get_insumo.estoque_minimo"
                      ></v-text-field>
                    </v-flex>
                    <v-flex class="" xs12 md4>
                      <v-select
                        label="Unidade do estoque mínimo"
                        dense
                        outlined
                        class="fonte-subtitulo fonte-italica"
                        placeholder="ex: M"
                        type="Number"
                        :readonly="get_insumo._id ? true : false"
                        :items="unidades_de_medida_base"
                        item-text="nome"
                        return-object
                        @input="forceUpdate"
                        @change="forceUpdate"
                        :rules="[v => !!v || 'Campo obrigatório']"
                        v-model="get_insumo.unidade_estoque_minimo"
                      ></v-select>
                    </v-flex> -->
                    <v-flex class="" xs12 md4>
                      <v-select
                        label="Unidade"
                        dense
                        outlined
                        class="fonte-subtitulo fonte-italica"
                        placeholder="ex: M"
                        type="Number"
                        :readonly="get_insumo._id ? true : false"
                        :items="unidades_de_medida_base"
                        item-text="nome"
                        return-object
                        @input="forceUpdate"
                        @change="forceUpdate"
                        :rules="[v => !!v || 'Campo obrigatório']"
                        v-model="get_insumo.unidade_estoque_minimo"
                      ></v-select>
                    </v-flex>
                    <v-flex>
                      <v-btn
                        class="fonte"
                        :color="$theme.third"
                        dark
                        outlined
                        @click="createOrUpdate"
                        >Salvar detalhes do item</v-btn
                      >
                    </v-flex>
                  </div>
                </v-flex>
              </v-form>
            </v-flex>
            <!-- Gerência do estoque -->
            <!-- <v-flex v-if="get_insumo._id" class="mt-6 pa-2 mb-3" xs12> -->
            <v-flex v-if="false" class="mt-6 pa-2 mb-3" xs12>
              <div class="expande-horizontal fonte column">
                <v-flex class="mb-3" xs12>
                  <transition name="slide-fade">
                    <v-list-item
                      @click="iniciaCadastroEstoque"
                      class="pa-0 ma-0"
                    >
                      <v-avatar color="#47CF72" class="mr-2" icon>
                        <v-icon dark>mdi-cube-send</v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">
                          {{ get_insumo.new ? "Novo item" : get_insumo.nome }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="font-mini">
                          {{ quantidadeEstoque.quantidade
                          }}{{
                            get_insumo && get_insumo.unidade_estoque_minimo
                              ? get_insumo.unidade_estoque_minimo.valor
                              : ""
                          }}
                          em estoque
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          :color="$theme.third"
                          class="text-capitalize"
                          @click="iniciaCadastroEstoque"
                          small
                        >
                          <v-icon color="white" class="mr-1" size="15"
                            >mdi-plus-circle-outline</v-icon
                          >
                          <span class="white--text fonte fonte-mini"
                            >Add Estoque</span
                          >
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </transition>
                </v-flex>
                <v-flex xs12>
                  <v-list three-line class="pa-0 ma-0">
                    <template v-for="estoque in get_estoques.docs">
                      <v-list-item
                        style="border-radius: 5px; background: #f2f2f2;"
                        class="ma-0 mb-2 pa-0"
                        :key="estoque._id"
                      >
                        <v-avatar
                          class="mx-3"
                          color="green"
                          v-if="estoque.quantidade > 0"
                        >
                          <v-icon size="18" color="white">
                            mdi-arrow-up-circle-outline
                          </v-icon>
                        </v-avatar>
                        <v-avatar class="mx-3" color="red" v-else>
                          <v-icon size="18" class="ml-2 mr-2" color="white">
                            mdi-arrow-down-circle-outline
                          </v-icon>
                        </v-avatar>
                        <v-list-item-content
                          class="fonte-subtitulo fonte-italica font-weight-bold"
                        >
                          <v-list-item-title>
                            {{ estoque.quantidade }}
                            {{
                              estoque && estoque.unidade_de_medida
                                ? estoque.unidade_de_medida.valor
                                : ""
                            }}
                            de
                            {{ estoque.produto.nome }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Preço Base:
                            {{ $helper.formataSaldo(estoque.preco_de_compra) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Total comprado:
                            {{ sumValueBuyed(estoque) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="fonte-micro"
                            >{{
                              $moment(estoque.created_at).format(
                                "DD/MM/YY [às] HH:mm"
                              )
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-flex>
              </div>
            </v-flex>
          </div>
        </v-card>
      </v-dialog>
    </v-sheet>
    <CreateStock :materials="[]" ref="createStock" />
  </layout>
</template>

<script>
import Filtros from "../components/Filtros.vue";
import VisualizacaoEmCard from "../components/VisualizacaoEmCard.vue";
import VisualizacaoEmLista from "../components/VisualizacaoEmLista.vue";
import Paginacao from "../components/Paginacao.vue";
import ListagemVazia from "../components/ListagemVazia.vue";
import ModalView from "../components/modalView.vue";
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
import ModalSendArchiveById from "../../shared/components/ModalSendById.vue";
import CreateStock from "@/apps/Insumos/components/ModalCreateStock.vue";

export default {
  data() {
    return {
      card: false,
      contextDialog: false,
      drawer: false,
      formEstoqueDialog: false,
      filter: {},
      options: [
        {
          nome: "Novo item",
          action: this.iniciarCadastro,
          icon: "mdi-plus"
        }
        // {
        //   nome: "Estoque",
        //   action: this.iniciaCadastroEstoque,
        //   icon: "mdi-plus"
        // }
      ],
      rotas: [
        {
          name: "Início",
          path: "/dashboard"
        },
        {
          name: "Produtos",
          path: "/insumos"
        }
      ],
      get_categoria: {
        insumos: []
      },
      nova_secao: "",
      formPrecoMultiplo: {},
      unidades_de_medida: [
        {
          nome: "Kilo(KG)",
          valor: "KG",
          divisor: 1000
        },
        {
          nome: "GRAMA(g)",
          valor: "g",
          divisor: 1
        },
        {
          nome: "Litro (L)",
          valor: "L",
          divisor: 1000
        },
        {
          nome: "Mililitro (ML)",
          valor: "ML",
          divisor: 1000
        },
        {
          nome: "UNIDADE",
          valor: "UN",
          divisor: 1
        },
        {
          nome: "CAIXA",
          valor: "CX",
          divisor: 1
        }
      ],
      unidades_de_medida_base: [
        {
          nome: "Kilo(KG)",
          valor: "KG",
          divisor: 1000
        },
        {
          nome: "Litro (L)",
          valor: "L",
          divisor: 1000
        },
        {
          nome: "UNIDADE",
          valor: "UN",
          divisor: 1
        },
        // {
        //   nome: "Metro Quadrado",
        //   valor: "M2",
        //   divisor: 100
        // }
        // {
        //   nome: "CAIXA",
        //   valor: "CX"
        // },
        // {
        //   nome: "PACOTE",
        //   valor: "PCT",
        //   divisor: 1
        // }
      ],
      itemsToShow: 10
    };
  },
  filters: {
    precoDeEntrada(val) {
      const quantidade = parseFloat(val.quantidade);
      const preco_de_compra = parseFloat(val.preco_de_compra);
      const preco_por_divisor = preco_de_compra / val.divisor;
      const total_de_entrada = quantidade * preco_por_divisor;
      return total_de_entrada;
    }
  },
  components: {
    Filtros,
    VisualizacaoEmCard,
    VisualizacaoEmLista,
    Paginacao,
    ListagemVazia,
    ModalView,
    ModalSendArchive,
    ModalSendArchiveById,
    CreateStock
  },
  computed: {
    ...mapGetters([
      "get_estoque",
      "get_estoques",
      "get_insumos_filtros",
      "get_estoques_filtros",
      "getLoggedUser",
      "get_insumo",
      "get_insumos",
      "getLinksById",
      "getPersonalizedLoading"
    ]),
    insumos() {
      if (this.filter.search) {
        return this.get_insumos.docs.filter(insumo => {
          return (
            insumo.nome
              .toLowerCase()
              .indexOf(this.filter.search.toLowerCase()) !== -1
          );
        });
      } else {
        const insumos = this.get_insumos.docs;
        return insumos.slice(0, this.itemsToShow);
      }
    },
    quantidadeEstoque() {
      const calcPreco = val => {
        const quantidade = parseFloat(val.quantidade);
        const preco_de_compra = parseFloat(
          val.preco_de_compra ? val.preco_de_compra : 0
        );
        const preco_por_divisor =
          preco_de_compra /
          (val.unidade_de_medida.divisor ? val.unidade_de_medida.divisor : 0);
        const total_de_entrada = quantidade * (preco_por_divisor || 0);
        return total_de_entrada;
      };
      let totalEstoque = {
        quantidade: 0,
        valor_total: 0
      };
      if (this.get_estoques.docs) {
        this.get_estoques.docs.map(estoque => {
          try {
            const type = estoque.type || "in";
            if (type === "in") {
              totalEstoque.quantidade += estoque.quantidade;
              totalEstoque.valor_total += calcPreco(estoque);
            }
            if (type === "out") {
              totalEstoque.quantidade -= estoque.quantidade;
              totalEstoque.valor_total -= calcPreco(estoque);
            }
          } catch (err) {
            console.log("33333333 e", err);
          }
        });
      }

      totalEstoque.quantidade = totalEstoque.quantidade.toFixed(2);
      return totalEstoque;
    }
  },
  methods: {
    onIntersect() {
      this.itemsToShow += 12;
    },
    excluir_insumo_dialog(item) {
      let text = "Deseja remover este insumo?";
      this.createConfirmAction({
        message: text,
        icon: "mdi-close-circle",
        action: "excluir_insumo",
        action_value: item
      });
    },
    excluir_estoque_dialog(item) {
      let text = "Deseja remover registro do estoque?";
      this.createConfirmAction({
        message: text,
        icon: "mdi-close-circle",
        action: "excluir_estoque",
        action_value: item
      });
    },
    backToList() {
      this.$store.commit("set_insumo", {});
      this.$forceUpdate();
    },
    sumValueBuyed(val) {
      let qtd = val.quantidade || 0;
      let priceBuy = val.preco_de_compra || 0;
      let sum = qtd * priceBuy;
      return this.$helper.formataSaldo(sum);
    },
    forceUpdate() {
      this.$forceUpdate();
    },
    iniciaCadastroEstoque() {
      this.$refs.createStock.open("stock_up", this.get_insumo);
      this.get_estoque.unidade_de_medida = this.get_insumo.unidade_estoque_minimo;
    },
    abririnsumo(insumo) {
      this.$store.commit("set_insumo", insumo);
      this.$store.commit("setLinks", insumo.imagens);
      this.get_estoques_filtros.produto = insumo;
      this.listar_estoques();
    },
    removePrecoMultiplo(index) {
      let novoPrecoMultiplo = this.get_insumo.preco_multiplo.filter(
        (p, i) => i !== index
      );
      this.get_insumo.preco_multiplo = novoPrecoMultiplo;
    },
    getLinkById(id) {
      return this.getLinkById[id];
    },
    closeContextDialog() {
      this.contextDialog = false;
      setTimeout(() => {
        this.drawer = true;
      }, 10);
    },
    poc(text) {
      console.log(text);
    },
    ...mapActions([
      "abre_modal_view_tenant",
      "listar_tenants",
      "listar_insumos",
      "criar_estoque",
      "listar_insumocategorias",
      "criar_insumo",
      "listar_estoques",
      "atualizar_insumo",
      "setPersonalizedLoading",
      "createConfirmAction"
    ]),
    adicionarNovoPreco() {
      if (this.$refs.formNewPrecoMultiplo.validate()) {
        const preco = this.formPrecoMultiplo;
        this.get_insumo.preco_multiplo.push(preco);
        this.formPrecoMultiplo = {};
      }
    },
    criarOuAtualizar() {
      if (this.get_insumo._id) {
        this.atualizar_insumo();
      } else {
        this.criar_insumo();
      }
    },
    adicionarEstoque() {
      if (this.$refs.formEstoque.validate()) {
        this.get_estoque.produto = this.get_insumo;
        this.get_estoque.origem = "insumo";
        this.criar_estoque();
        this.formEstoqueDialog = false;
      }
    },
    imprimir() {
      this.card = false;
      this.$refs.html2Pdf.generatePdf();
    },
    iniciarCadastro() {
      this.setPersonalizedLoading("animate__fadeOutTopRight");
      this.$store.commit("set_insumo", {
        new: true,
        preco_multiplo: []
      });
      this.$store.commit("setLinks", []);
      this.setPersonalizedLoading("animate__fadeInUp");
      this.$store.commit("set_estoques", {
        docs: [],
        page: 1,
        totalPages: 1
      });
    },
    createOrUpdate() {
      if (this.$refs.form.validate()) {
        if (this.get_insumo._id) {
          this.atualizar_insumo();
        } else {
          this.criar_insumo();
        }
      }
    }
  },
  created() {
    this.get_insumos_filtros.limitPerPage = 7;
    this.get_insumos_filtros.lista_de_compra = false;
    this.listar_insumos();
  }
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.item-list {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #1173ff;
}
</style>
